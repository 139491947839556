
::v-deep p {
    padding: 0;
    margin: 0;
}

.detail-paper {
    display: flex;
    flex-direction: column;
    padding: 38px 20px;
    box-sizing: border-box;

    .student-info {
        display: flex;

        .student-info-left {
            .left-img {
                width: 100px;
                height: 140px;
                box-sizing: border-box;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .student-info-right {
            display: flex;
            flex: 1;
            width: 1%;
            flex-direction: column;
            justify-content: space-between;
            padding: 5px 0 5px 20px;
            box-sizing: border-box;

            .info-right-top, .info-right-bottom {
                padding: 0 64px;
                background: #F3FFF8;
                height: 50px;
                display: flex;
                align-items: center;

                span {
                    font-size: 18px;
                    display: inline-block;
                    width: 30%;
                }

                &.info-right-bottom {
                    span {
                        &:first-of-type {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }

    .paper-table {
        display: flex;
        margin: 24px 0;

        .paper-total {
            display: flex;
            flex-direction: column;
            border: 1px solid #EAEAEA;

            .total, .score {
                display: flex;
                flex-direction: column;
                padding: 24px 59px;
                align-items: center;

                span {
                    line-height: 1;

                    &:nth-child(1) {
                        font-size: 18px;
                        font-weight: 400;
                    }

                    &:nth-child(2) {
                        margin-top: 20px;
                        font-size: 18px;
                        font-weight: 400;

                        strong {
                            font-size: 24px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .score {
                border-top: 1px solid #EAEAEA;
                /*span:nth-child(2){*/
                /*    color: #FF0000;*/
                /*}*/
            }
        }

        .answer-case {
            display: flex;
            flex-direction: column;
            border: 1px solid #EAEAEA;
            border-left: none;

            .case-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20%;
                padding: 0px 40px;
                border-bottom: 1px solid #EAEAEA;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: 400;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .topic-type {
            display: flex;
            flex-direction: column;
            border: 1px solid #EAEAEA;
            border-left: none;
            flex: 1;

            .type-item {
                padding: 0px 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20%;
                border-bottom: 1px solid #EAEAEA;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: 400;

                .text-gray {
                    font-weight: 400;
                    color: #999999;
                }

                &:last-child {
                    border-bottom: none;
                    color: #333;
                }

                &:nth-child(3) {
                    color: #FF0000;
                }

                &:nth-child(4) {
                    color: #999999;
                }
            }

            &:last-child {
                width: 1%;
                flex: 1;
                border-right: none;
            }

            .answer-item:nth-child(1) {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20%;
                border-bottom: 1px solid #EAEAEA;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: 400;

                .text-gray {
                    font-weight: 400;
                    color: #999999;
                }
            }

            .answer-item:nth-child(2) {
                display: flex;
                height: 1%;
                flex: 1;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 400;
                box-sizing: border-box;
                /*color: #FF0000;*/
            }
        }
    }

    .paperDetail-content {
        .questionType-item {
            margin-top: 20px;

            .questionType-title {
                display: flex;
                align-items: center;

                .green-block {
                    display: inline-block;
                    width: 5px;
                    height: 20px;
                    background: #2DC079;
                    border-radius: 3px;
                    margin-right: 10px;
                }

                .title {
                    font-size: 18px;
                    color: #333;
                    font-weight: 400;
                }

                .subtitle {
                    font-size: 18px;
                    color: #999999;
                    margin-left: 10px;
                }
            }

            .question-item {
                font-size: 14px;
                font-weight: 500;
                /*padding: 22px 40px;*/
                .question-title {
                    margin-top: 20px;
                    //height: 60px;
                    background: #F7F7F7;
                    line-height: 60px;
                    padding: 0px 40px;
                    //display: flex;
                    //align-items: center;
                    ::v-deep p {
                        display: inline;
                    }
                }

                .answer-box {
                    .question-answer {
                        padding: 0 40px;
                        display: flex;
                        flex-direction: column;

                        .answer-item {
                            margin-top: 20px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333;

                            &.el-radio {
                                white-space: normal;
                            }

                            ::v-deep .el-radio__input {
                                white-space: normal;

                                &.is-checked {
                                    & + .el-radio__label {
                                        color: #333;
                                    }

                                    .el-radio__inner {
                                        border-color: #A0A0A0;
                                        background: #fff;

                                        &::after {
                                            background-color: #A0A0A0;
                                        }
                                    }
                                }

                                .el-radio__inner {
                                    &:hover {
                                        border-color: #A0A0A0;
                                    }
                                }
                            }

                            &.el-checkbox {
                                white-space: normal;
                            }

                            ::v-deep .el-checkbox__input {
                                white-space: normal;

                                &.is-checked {
                                    & + .el-checkbox__label {
                                        color: #333;
                                    }

                                    .el-checkbox__inner {
                                        background-color: #A0A0A0;
                                        border-color: #A0A0A0;
                                    }
                                }

                                &.is-indeterminate {
                                    .el-checkbox__inner {
                                        background-color: #A0A0A0;
                                        border-color: #A0A0A0;
                                    }
                                }

                                .el-checkbox__inner {
                                    &:hover {
                                        border-color: #A0A0A0;
                                    }
                                }
                            }
                        }

                        .answer-content {
                            display: flex;
                            flex-wrap: wrap;

                            .answer-item {
                                margin-left: 30px;

                                &:first-of-type {
                                    margin-left: 0;
                                }
                            }

                            //.answer-after{
                            //  &:after{
                            //    content: '、';
                            //  }
                            //}
                            //.answer-after:last-child{
                            //  &:after{
                            //    content: '';
                            //  }
                            //}
                        }
                    }

                    .question-analysis-error {
                        background: #FDF0ED;
                    }

                    .question-analysis-correct {
                        background: #F3FFF8;
                    }

                    .question-analysis {
                        position: relative;
                        margin-top: 50px;
                        padding: 13px 54px;
                        display: flex;
                        flex-wrap: wrap;

                        span {
                            margin-right: 60px;
                            line-height: 1;
                            font-size: 14px;
                            font-weight: 400;

                            .strong-error {
                                color: #FF0000;
                            }

                            .strong-correct {
                                color: #1EB788;
                            }

                            .strong-after {
                                &:after {
                                    content: '、';
                                }

                                &:last-child {
                                    &:after {
                                        content: '';
                                    }
                                }
                            }

                            strong {
                                font-weight: 400;
                            }
                        }

                        .answer-judge {
                            position: absolute;
                            left: 40px;
                            top: -30px;
                            line-height: 1;
                            padding: 8px 14px;
                            border-radius: 4px 4px 0px 0px;
                        }

                        .answer-judge-error {
                            background: #FDF0ED;
                            color: #FF0000;
                        }

                        .answer-judge-correct {
                            background: #F3FFF8;
                            color: #1EB788;
                        }

                        .isAnalysis {
                            display: flex;
                            align-items: center;

                            span {
                                cursor: pointer;
                                line-height: 1;
                            }
                        }
                    }

                    .answer-reference {
                        padding: 18px 40px;
                        display: flex;
                        flex-direction: column;
                        font-size: 14px;
                        color: #666;
                        line-height: 24px;

                        .reference-title {
                            color: #FF0000;
                        }

                        .reference-score {
                            margin-top: 22px;
                            display: flex;
                            align-items: center;

                            ::v-deep .el-input {
                                margin: 0 10px;

                                .el-input__inner {
                                    color: #FF0000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.isAnalysis-content {
    padding: 18px 54px;
    border: 1px solid #EEEEEE;
    font-size: 14px;

    .title {
        color: #FF0000;
    }

    .analysis-span {
        ::v-deep p {
            display: inline;
            font-size: 14px;
            color: #333;
        }
    }
}

::v-deep .student-dialog {
    .el-dialog__header {
        padding: 16px 24px;
        background: #2DC079;

        span, i {
            font-size: 16px;
            line-height: 1;
            color: #fff;
        }
    }

    .el-dialog__body {
        padding: 22px 24px;
        height: 300px;
        display: flex;
        flex-direction: column;
    }
}
